body {
  background: url("/assets/images/issue-01/paradigm/light.jpg");
  background-size: cover;
}

body.dark {
  background: url("/assets/images/issue-01/paradigm/dark.jpg");
  background-size: cover;
}

/* Header */
.header.nav_text {
  transition: color 0.3s ease-in-out;
}
.header.header--black {
  color: black;
}
.header.header--white {
  color: white;
}

/* ISSUES  */

/* Desktop Mode */
.issues {
  display: flex;
}

.issues .issues-issue {
  width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.issues .issues-issue .issues-issue-title {
  font-family: "berthold";
  font-size: 24px;
}

.issues .issues-issue .issues-issue-cover {
  height: calc(60vw / 3 * 1.4);
  display: flex;
  align-items: center;
}

.issues .issues-issue .issues-issue-cover img:not(#matches_cover) {
  max-height: 80%;
}

.issues .issues-issue .issues-issue-city {
  font-family: "berthold";
  font-size: 20px;
  transition: color 1s ease-in-out;
}

.issues .issues-issue :hover .issues-issue-city {
  color: white;
}

.issues-issue-date {
  display: none; /* Hide on Desktop */
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 4.5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  color: #c8c8c8;
  font-size: 20px;
}

/* Mobile Version */
@media screen and (max-width: 812px) {
  /* Only Show Current Issue */
  .issues .issues-issue:not(.issue--current-issue) {
    display: none;
  }
  /* Current Issue Settings  */
  .issues .issues-issue.issue--current-issue {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .issues .issues-issue .issues-issue-cover {
    margin-top: 10vh;
    height: 80vh;
    pointer-events: none;
  }
  .issues .issues-issue .issues-issue-title {
    display: none;
  }
  #issue-2 .issues .issues-issue .issues-issue-title {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 9vw;
    left: 0;
    font-size: 9.5vw;
    font-family: everett;
    color: rgb(200, 200, 200);
    white-space: nowrap;
  }

  .issues .issues-issue .issues-issue-title br {
    display: none;
  }
  .issues .issues-issue .issues-issue-city {
    display: none;
  }
  .issues-issue-date {
    display: block;
  }
}

/* Sections  */

.section.section--background-color--white {
  background-color: white;
}
.section.section--background-color--black {
  background-color: black;
}
.section .section--background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

/* Slides and Scrolling Wrapper*/

.slide {
  position: relative;
}
.slide.slide--background-color--black {
  background-color: black;
}

.slide.slide--background-color--white {
  background-color: white;
}

.slide .slide-title,
.scrolling-wrapper .slide-title {
  z-index: 2;
  position: absolute;
  top: 4.5vw;
  right: 4.5vw;
  font-family: everett;
  font-size: 2.8vw;
}

.slide .slide-title.slide-title--color--white,
.scrolling-wrapper .slide-title.slide-title--color--white {
  color: white;
}

.slide-title .slide-title--subtitle {
  margin-top: 0px;
  text-align: right;
}

.slide .slide--background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.slide .slide--background-image.slide--background-image--contain {
  object-fit: contain;
}

.slide .slide--background-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

/* Section 01 */
.section.s1 .section-title {
  display: none;
}

/* Mobile Version */
@media screen and (max-width: 812px) {
  .section.s1 .section-title {
    z-index: 2;
    display: block;
    color: rgb(200, 200, 200);
    font-family: "everett";
    top: 0;
    left: 0;
    font-size: 9.5vw;
    line-height: 12vw;
    position: fixed;
    text-align: left;
    padding-left: 5vw;
    padding-top: 10vw;
  }
}

/* audio player */
.container.audio-controls {
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 1;
  padding: 5vw;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.container.audio-controls button {
  padding: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  pointer-events: all;
}

.container.audio-controls button svg {
  height: 2rem;
  width: 2rem;
}

/* video player */
.video-controls,
.audio-controls {
  box-sizing: border-box;
  padding: 5vw 5vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  pointer-events: none;
}

.video-controls {
  justify-content: center;
  align-items: center;
}

.audio-controls {
  z-index: 2;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 4.5vw;
  padding-bottom: 1.5vw;
}

.video-controls button,
.audio-controls button {
  opacity: 0.7;
  background: none;
  border: none;
  font-size: 25vw;
  pointer-events: all;
  color: white;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.video-controls button.is-playing {
  opacity: 0;
}

.audio-controls button {
  padding: 0;
  margin: 0;
  font-size: 7vw;
}

.audio-controls button svg {
  height: 2rem;
  width: 2rem;
}

/* Temporarily Deactivate */
/* .video-controls,
.audio-controls {
  display: none;
  pointer-events: none;
} */

@media screen and (min-width: 812px) {
  .video-controls,
  .audio-controls {
    display: none;
    pointer-events: none;
  }
}
