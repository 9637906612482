body {
  background: url("light.c1ddad26.jpg") 0 0 / cover;
}

body.dark {
  background: url("dark.b84f17b1.jpg") 0 0 / cover;
}

.header.nav_text {
  transition: color .3s ease-in-out;
}

.header.header--black {
  color: #000;
}

.header.header--white {
  color: #fff;
}

.issues {
  display: flex;
}

.issues .issues-issue {
  width: 33vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.issues .issues-issue .issues-issue-title {
  font-family: berthold;
  font-size: 24px;
}

.issues .issues-issue .issues-issue-cover {
  height: 28vw;
  align-items: center;
  display: flex;
}

.issues .issues-issue .issues-issue-cover img:not(#matches_cover) {
  max-height: 80%;
}

.issues .issues-issue .issues-issue-city {
  font-family: berthold;
  font-size: 20px;
  transition: color 1s ease-in-out;
}

.issues .issues-issue :hover .issues-issue-city {
  color: #fff;
}

.issues-issue-date {
  color: #c8c8c8;
  padding-bottom: 4.5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-size: 20px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 812px) {
  .issues .issues-issue:not(.issue--current-issue) {
    display: none;
  }

  .issues .issues-issue.issue--current-issue {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .issues .issues-issue .issues-issue-cover {
    height: 80vh;
    pointer-events: none;
    margin-top: 10vh;
  }

  .issues .issues-issue .issues-issue-title {
    display: none;
  }

  #issue-2 .issues .issues-issue .issues-issue-title {
    width: 100%;
    color: #c8c8c8;
    white-space: nowrap;
    font-family: everett;
    font-size: 9.5vw;
    display: block;
    position: absolute;
    bottom: 9vw;
    left: 0;
  }

  .issues .issues-issue .issues-issue-title br, .issues .issues-issue .issues-issue-city {
    display: none;
  }

  .issues-issue-date {
    display: block;
  }
}

.section.section--background-color--white {
  background-color: #fff;
}

.section.section--background-color--black {
  background-color: #000;
}

.section .section--background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.slide {
  position: relative;
}

.slide.slide--background-color--black {
  background-color: #000;
}

.slide.slide--background-color--white {
  background-color: #fff;
}

.slide .slide-title, .scrolling-wrapper .slide-title {
  z-index: 2;
  font-family: everett;
  font-size: 2.8vw;
  position: absolute;
  top: 4.5vw;
  right: 4.5vw;
}

.slide .slide-title.slide-title--color--white, .scrolling-wrapper .slide-title.slide-title--color--white {
  color: #fff;
}

.slide-title .slide-title--subtitle {
  text-align: right;
  margin-top: 0;
}

.slide .slide--background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.slide .slide--background-image.slide--background-image--contain {
  object-fit: contain;
}

.slide .slide--background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.section.s1 .section-title {
  display: none;
}

@media screen and (max-width: 812px) {
  .section.s1 .section-title {
    z-index: 2;
    color: #c8c8c8;
    text-align: left;
    padding-top: 10vw;
    padding-left: 5vw;
    font-family: everett;
    font-size: 9.5vw;
    line-height: 12vw;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.container.audio-controls {
  opacity: 0;
  z-index: 2;
  opacity: 1;
  box-sizing: border-box;
  pointer-events: none;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5vw;
  transition: opacity .3s ease-in-out;
  display: flex;
  top: 0;
  left: 0;
}

.container.audio-controls button {
  opacity: 0;
  color: #fff;
  cursor: pointer;
  pointer-events: all;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.container.audio-controls button svg {
  height: 2rem;
  width: 2rem;
}

.video-controls, .audio-controls {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding: 5vw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.video-controls {
  justify-content: center;
  align-items: center;
}

.audio-controls {
  z-index: 2;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 1.5vw;
  padding-left: 4.5vw;
}

.video-controls button, .audio-controls button {
  opacity: .7;
  pointer-events: all;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 25vw;
  transition: opacity .3s ease-in-out;
}

.video-controls button.is-playing {
  opacity: 0;
}

.audio-controls button {
  margin: 0;
  padding: 0;
  font-size: 7vw;
}

.audio-controls button svg {
  height: 2rem;
  width: 2rem;
}

@media screen and (min-width: 812px) {
  .video-controls, .audio-controls {
    pointer-events: none;
    display: none;
  }
}

/*# sourceMappingURL=index.573d6b96.css.map */
